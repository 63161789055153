import React from 'react'

function Services() {
	return (
		<div className='flex flex-col items-center my-20'>
			<div className='flex flex-col items-center mb-10 ml-10 mr-10 text-center'>
				<h2 className='lg:text-3xl sm:text-xl mb-3'>NOS SERVICES</h2>
				<p className='lg:text-lg opacity-50'>LCSecurity vous offre une gamme étendue d'expertises pour répondre précisément à vos besoins.</p>
				<div class="h-1.5 w-28 mt-2 rounded-sm bg-gold"></div>
			</div>
			<div className='flex flex-col items-center space-y-10'>
				<div className='flex flex-col items-center space-y-3 ml-10 mr-10 text-center'>
					<div className='bg-gold rounded-full p-4 text-white animate-bounce hover:bg-opacity-80'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h20.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" /></svg></div>
					<h2 className='lg:text-2xl'>SÉCURITÉ</h2>
					<p className='lg:text-lg opacity-50'>Nous protégeons vos biens et veillons sur votre sécurité personnelle et/ou la sécurité de vos évenements, quelle que soit leur envergure.</p>
					<div class="h-1.5 w-20 rounded-sm bg-gold"></div>
				</div>
				<div className='flex flex-col items-center space-y-3 ml-10 mr-10 text-center'>
					<div className='bg-gold rounded-full p-4 text-white animate-bounce hover:bg-opacity-80'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" /></svg></div>
					<h2 className='lg:text-2xl'>SURVEILLANCE</h2>
					<p className='lg:text-lg opacity-50'>Nous patrouillons pour dissiper toute incertitude et garantir votre tranquilité, nous garantissons la sûreté et gérons les accès sur vos sites.</p>
					<div class="h-1.5 w-20 hover:w-16 rounded-sm bg-gold"></div>
				</div>
				<div className='flex flex-col items-center space-y-3 ml-10 mr-10 text-center'>
					<div className='bg-gold rounded-full p-4 text-white animate-bounce hover:bg-opacity-80'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" /></svg></div>
					<h2 className='lg:text-2xl'>AUDIT ET CONSEILS</h2>
					<p className='lg:text-lg opacity-50'>Nous évaluons et conseillons vos dispositifs de sécurité.</p>
					<div class="h-1.5 w-20 rounded-sm bg-gold"></div>
				</div>
			</div>
		</div>
	)
}

export default Services