import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/scoro.png';

function Navbar() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <div className='sticky top-0 z-50 bg-white flex justify-between'>
      <div>
        <img src={logo} alt='Logo Lcsecurity' title='Logo agence de securité' loading='eager' className='h-auto min-h-16 max-h-32 w-auto object-cover'/>
      </div>
      <div className='flex items-center mr-10 text-l '>
        <ul className='flex space-x-2 text-sm lg:text-lg whitespace-nowrap'>
          <li><Link to="/" onClick={scrollToTop} className='text-black underline underline-offset-8 px-3 hover:text-gold'>Accueil</Link></li>
          <li><Link to="/about" onClick={scrollToTop} className='text-black underline underline-offset-8 px-3 hover:text-gold'>À propos</Link></li>
          <li><Link to="/contact" onClick={scrollToTop} className='text-black underline underline-offset-8 px-3 hover:text-gold'>Contactez nous</Link></li>
          <li><Link to="/devis" onClick={scrollToTop} className='text-black underline underline-offset-8 px-3 hover:text-gold'>Devis</Link></li>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
