import React from 'react'
import Entry from '../components/Entry'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Form from '../components/Form'

function Devis() {
  return (
    <div>
      <Navbar />
      <Entry title="Devis" subtitle="Les équipes de LCSecurity se feront un plaisir de vous proposer la meilleure solution pour vos évènements et vos besoins en sécurité." />
      <Form />
      <Footer />
    </div>
  )
}

export default Devis