import React from 'react'
import logo from '../images/secuexpert.png'
import { Link } from 'react-router-dom'

function Expert() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  
  return (
    <div className='flex justify-center items-center sm:flex-row flex-col  space-x-6'>
      <div className='sm:w-3/12 p-2 sm:min-w-[400px]'>
        <div className='mb-10'>
          <div class="h-1.5 w-28 mt-2 rounded-sm bg-gold"></div>
          <div class="h-1.5 w-16 my-2 rounded-sm bg-gold"></div>
          <h2 className='lg:text-2xl' >CONFIEZ VOTRE SÉCURITÉ À UN EXPERT.</h2>
        </div>
        <p className='break-normal mb-10 opacity-50'>LCSecurity, votre partenaire en matière de sécurité et de gardiennage, garantit votre protection en continu, 24h/24 et 7j/7. Nous mettons à votre service avec réactivité des professionnels qualifiés tels que des vigiles, des agents de filtrage, des agents SSIAP et des maîtres-chiens pour assurer votre sécurité et prévenir toute intrusion ou acte de malveillance.</p>
        <div className="flex justify-center gap-2 m-5">
					<Link to="/contact" onClick={scrollToTop} className='no-underline text-white bg-gold p-4 rounded-md hover:bg-opacity-80 text-sm md:text-sm lg:text-md' >Contactez Nous</Link>
				</div>
      </div>
      <div><img src={logo} alt='lcsecurity illustration' /></div>
    </div>
  )
}

export default Expert