import React from 'react'
import logo from '../images/cams.png'

function ThirdAbout() {
  return (
    <div className='flex justify-center items-center sm:flex-row flex-col-reverse space-x-6'>
      <div className=''><img src={logo} alt='Expert securité' /></div>
      <div className='sm:w-3/12 p-2 sm:min-w-[400px]'>
        <div className='mb-10'>
          <div class="h-1.5 w-28 mt-2 rounded-sm bg-gold"></div>
          <div class="h-1.5 w-16 my-2 rounded-sm bg-gold"></div>
          <h2 className='lg:text-2xl' >RECRUTEMENT ET GESTION DU PERSONNEL​</h2>
        </div>
        <p className='break-normal mb-10 opacity-50'>Notre priorité est la gestion quotidienne de notre effectif et de nos missions. Nos agents sont formés et la sélection rigoureuse des candidatures alliées à des tests d’évaluations nous permettent une affectation sur site en toute confiance.</p>
      </div>
    </div>
  )
}

export default ThirdAbout