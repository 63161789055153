import './styles/index.css';
import { Routes, Route } from "react-router-dom"
import Home from './pages/Home'
import About from './pages/About'
import Contact from './pages/Contact';
import Devis from './pages/Devis';

function App() {
  return (
    <div className='font-poppins'>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="devis" element={<Devis />} />
      </Routes>
    </div>
  );
}

export default App;
