import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Entry from '../components/Entry'
import FirstAbout from '../components/FirstAbout'
import SecondAbout from '../components/SecondAbout'
import ThirdAbout from '../components/ThirdAbout'
function About() {
  return (
    <div>
        <Navbar />
        <Entry title="À propos" subtitle="Tout savoir sur LCSecurity" />
        <FirstAbout />
        <SecondAbout />
        <ThirdAbout />
        <Footer />
    </div>
  )
}

export default About