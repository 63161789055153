import React from 'react'
import Entry from '../components/Entry'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Infos from '../components/Infos'

function Contact() {
  return (
    <div>
      <Navbar />
      <Entry title="Contactez Nous" subtitle="appelez ou envoyez un mail, nous seront ravis de vous répondre dans les plus brefs délais" />
      <Infos />
      <Footer />
    </div>
  )
}

export default Contact