import React from 'react'
import logo from '../images/guard.png'

function SecondAbout() {
  return (
    <div className='flex justify-center items-center sm:flex-row flex-col space-x-6'>
      <div className='sm:w-3/12 p-2 sm:min-w-[400px]'>
        <div className='mb-10'>
          <div class="h-1.5 w-28 mt-2 rounded-sm bg-gold"></div>
          <div class="h-1.5 w-16 my-2 rounded-sm bg-gold"></div>
          <h2 className='lg:text-2xl' >PERSONNEL DE QUALITÉ​</h2>
        </div>
        <p className='break-normal mb-10 opacity-50'>À vrai dire, un agent de sécurité qualifié ne court pas les rues. Il faut avoir le flaire professionnel pour le saisir et le garder. Chez LCSecurity, nous évaluons la qualité du personnel à partir de critères pertinents.</p>
      </div>
      <div className=''><img src={logo} alt='Expert securité' /></div>
    </div>
  )
}

export default SecondAbout