import React from 'react'
import Navbar from '../components/Navbar'
import Entry from '../components/Entry'
import Services from '../components/Services'
import Expert from '../components/Expert'
import UrgentBooking from '../components/UrgentBooking'
import Footer from '../components/Footer'
import Infos from '../components/Infos'

function Home() {
  return (
    <div>
      <Navbar />
      <Entry title="AGENCE DE SÉCURITÉ PRIVÉE" subtitle="Votre sécurité, notre engagement." />
      <Services />
      <Expert />
      <UrgentBooking />
      <Infos />
      <Footer />
    </div>
  )
}

export default Home