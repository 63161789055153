import React from 'react'
import logo from '../images/Home.jpg'

function Entry({title, subtitle}) {
	return (
		<div>
			<div className='relative h-[700px] w-full'>
				<img src={logo} alt='sécurité' title='Image LCsecurity' loading='eager' className='h-full w-full object-cover' />
				<div className="absolute inset-0 grid h-full w-full place-items-center bg-black/75">
					<div className="w-3/4 text-center md:w-2/4 text-white">
						<h1 className="mb-4 text-3xl md:text-4xl lg:text-5xl">{title}</h1>
						<p className="mb-12 opacity-80 text-sm md:text-sm lg:text-xl">{subtitle}</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Entry