import React from 'react'
import logo from '../images/handshake.png'

function UrgentBooking() {
  return (
    <div className='flex justify-center items-center sm:flex-row flex-col-reverse space-x-6'>
      <div className=''><img src={logo} alt='Expert securité' /></div>
      <div className='sm:w-3/12 p-2 sm:min-w-[400px]'>
        <div className='mb-10'>
          <div class="h-1.5 w-28 mt-2 rounded-sm bg-gold"></div>
          <div class="h-1.5 w-16 my-2 rounded-sm bg-gold"></div>
          <h2 className='lg:text-2xl' >PRISE EN COMPTE DES DEMANDES URGENTES.</h2>
        </div>
        <p className='break-normal mb-10 opacity-50'>LCSecurity est la société de gardiennage et sécurité, qui assure votre sécurité 24h/24 et 7j/7. En cas d’urgence notre équipe est prête. Grâce à cela nous avons un nombre important d’agents volontaires pour effectuer des renforts.</p>
      </div>
    </div>
  )
}

export default UrgentBooking