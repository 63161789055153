import React from 'react'
import logo from '../images/mngmt.png'

function FirstAbout() {
  return (
    <div className='flex justify-center items-center sm:flex-row flex-col-reverse space-x-6'>
      <div className=''><img src={logo} alt='Expert securité' /></div>
      <div className='sm:w-3/12 p-2 sm:min-w-[400px]'>
        <div className='mb-10'>
          <div class="h-1.5 w-28 mt-2 rounded-sm bg-gold"></div>
          <div class="h-1.5 w-16 my-2 rounded-sm bg-gold"></div>
          <h2 className='lg:text-2xl' >ORGANISATION ET MANAGEMENT​</h2>
        </div>
        <p className='break-normal mb-10 opacity-50'>Une organisation à l’image des entreprises modernes et un management basé sur la qualité du recrutement, le tout allié à une logistique adaptée. Une gestion transparente, un service développement et d’exploitation, un service administratif, un service planning et un service de contrôle.  Une équipe mixte et polyvalente. </p>
      </div>
    </div>
  )
}

export default FirstAbout