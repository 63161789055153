import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  return (
    <div>
      <div className='bg-white pt-10'>
        <div className='max-w-7xl mx-auto px-4'>
          <div className='flex justify-between items-center text-sm lg:text-xl whitespace-nowrap'>
            <div>
              <p>&copy; 2023 LCSecurity</p>
            </div>
            <div className='flex lg:space-x-2 text-xs lg:text-lg whitespace-nowrap'>
              <Link to="/" onClick={scrollToTop} className='text-black px-3 hover:text-gold'>Accueil</Link>
              <Link to="/about" onClick={scrollToTop} className='text-black px-3 hover:text-gold'>À propos</Link>
              <Link to="/contact" onClick={scrollToTop} className='text-black px-3 hover:text-gold'>Contactez nous</Link>
              <Link to="/devis" onClick={scrollToTop} className='text-black px-3 hover:text-gold'>Devis</Link>
            </div>
          </div>
        </div>
        <div className='text-xs p-4 flex flex-col justify-center space-y-1'>
          <div className='flex justify-center'><p>⁠AUT-078-2123-02-1320240902094</p></div>
          <div className='flex justify-center'><p>Article L612-14 du csi: L'autorisation administrative préalable ne confère aucun caractère officiel à l'entreprise ou aux personnes qui en bénéficient.</p></div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
